<template>
  <div class="rounded border shadow hover:shadow-2xl bg-white" v-if="company">
    <div
      class="pl-4 w-48 uppercase text-sm text-left font-medium bg-gray-200 border-r border-b"
    >
      <span class="mr-1"><fa :icon="['fal', 'database']" /></span>
      {{ $t('company_tag') }}
    </div>
    <div class="px-4">
      <div class="text-2xl">
        <div class="text-2xl font-medium py-2">{{ company.name }}</div>

        <!-- <div class="pb-2">
          <div class="tags">
            <span class="tag is-danger is-light" v-show="isManager">{{$t('account_roles_manager')}}</span>
            <span class="tag is-light" v-show="isEmployee">{{$t('account_roles_user')}}</span>
            <span class="tag is-light" v-show="isRW">{{$t('account_accesses_rw')}}</span>
            <span class="tag is-light" v-show="isRO">{{$t('account_accesses_ro')}}</span>
          </div>
        </div> -->
      </div>
    </div>

    <div class="flex border-t">
      <router-link
        v-if="isManager"
        :to="{ name: 'company', params: { cid: company.id } }"
        class="card-link"
      >
        {{ $t('company_card_management') }}
      </router-link>

      <router-link
        v-if="isEmployee"
        :to="{
          name: 'sheets-year',
          params: { cid: company.id, aid: uid, year },
        }"
        class="card-link"
      >
        {{ $t('company_card_sheets') }}
      </router-link>

      <router-link
        v-if="isEmployee"
        :to="{ name: 'account', params: { cid: company.id, aid: uid } }"
        class="card-link"
      >
        {{ $t('company_card_account') }}
      </router-link>
    </div>
  </div>
</template>

<style lang="postcss">
.card-link {
  @apply font-medium py-2 px-4 flex-1 border-r text-center;
}
.card-link:hover {
  @apply bg-gray-100;
}
.card-link:only-child {
  @apply text-left;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { contains } from 'ramda';
export default {
  name: 'CompanyCard',
  props: {
    company: Object,
  },
  computed: {
    ...mapGetters('auth', ['uid']),
    ...mapGetters('perms', ['isAdmin']),
    isManager() {
      return (
        this.isAdmin || contains(this.uid, this.company?.managerUids ?? [])
      );
    },
    isEmployee() {
      return contains(this.uid, this.company?.employeeUids ?? []);
    },
    isRW() {
      return contains(this.uid, this.company?.rwUids ?? []);
    },
    isRO() {
      return !this.isRW;
    },
    year() {
      return String(new Date().getFullYear());
    },
  },
};
</script>
