<template>
  <main class="bg-gray-200">
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{name: 'home'}"><a>{{$t('breadcrumb_home')}}</a></router-link>
            <router-link tag="li" :to="{name: 'companies'}"><a>{{$t('breadcrumb_companies')}}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-show="loading" />
    <sqr-error :error="loadError" />
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <div class="columns is-centered">
          <div
            class="column is-12-mobile is-7-tablet is-6-desktop is-5-widescreen"
          >
            <div class="buttons is-right">
              <sqr-router-link
                icon="plus"
                label="companies_start"
                to-name="start-company"
                color="primary"
              />
            </div>
            <company-card
              v-for="company in records"
              :key="company.id"
              :company="company"
              class="mb-8"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="px-4 md:px-0 pt-2 border-t bg-white sticky bottom-0">
      <div class="container">
        <sqr-fire-page
          v-bind="pagination"
          @page-set="pageSet"
          @page-size="pageSize"
        />
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrRouterLink from '@/sqrd/SqrRouterLink';
import SqrError from '@/sqrd/SqrError';
import SqrFirePage from '@/sqrd/SqrFirePage';
import CompanyCard from '@/components/CompanyCard';
export default {
  name: 'Companies',
  components: {
    SqrProgress,
    SqrRouterLink,
    SqrError,
    SqrFirePage,
    CompanyCard,
  },
  computed: {
    ...mapState('companies', ['records', 'loading', 'loadError']),
    ...mapGetters('companies', ['pagination']),
  },
  methods: {
    ...mapActions('companies', ['pageSet', 'pageSize']),
  },
};
</script>
